import { getLayout as IndexLayout } from "@/components/Layout"
import HomeSearch from "@/components/Home/HomeSearch"
import Pick from "@/components/Home/Pick"
import Brands from "@/components/Home/Brands"
import Latest from "@/components/Home/Latest/index"
import Collections from "@/components/Home/Collections"
import Trend from "@/components/Home/Trend"
import Discover from "@/components/Home/Discover/index"
import HowItWorks from "@/components/Home/HowItWorks"
import Gift from "@/components/Home/Gift"
import {
  getHomeContent,
  getCollectionsFeatured,
  getBrandsFeatured,
  getTrendingNow,
  getKronosPicks,
  getLatest,
} from "@/services/contents"
import { useRouter } from "next/router"
import Meta from "@/components/Meta"
import getServerProps, { redirectLocale } from "@/services/utils/getServerProps"
import Head from "next/head"

export const getServerSideProps = async (props) => {
  const redirect = redirectLocale(props)
  if (redirect) return redirect
  const homeContentData = await getHomeContent()
  const trendingNowData = await getTrendingNow()
  const kronosPicksData = await getKronosPicks()
  const collectionsData = await getCollectionsFeatured()
  const brandsData = await getBrandsFeatured()
  const latestData = await getLatest()
  return {
    props: {
      ...(await getServerProps(props)),
      homeContentData,
      trendingNowData,
      kronosPicksData,
      collectionsData,
      brandsData,
      latestData,
    },
  }
}

export default function Home({
  homeContentData,
  trendingNowData,
  kronosPicksData,
  collectionsData,
  brandsData,
  latestData,
}) {
  const router = useRouter()

  function onSearch(query) {
    router.push(`/watch?keyword=${query}`)
  }

  return (
    <div>
      <Meta path="/" />
      <Head>
        <meta
          key="og:image"
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_DOMAIN}/image/why-kronos-1@2x.jpg`}
        />
        :
      </Head>
      <div className="bg-neutral-50">
        <HomeSearch data={homeContentData} onSubmit={onSearch}></HomeSearch>
        <div className="relative">
          <div className="z-10 mx-auto px-2.5 -mt-40 tablet:px-0">
            <Pick items={kronosPicksData.results}></Pick>
          </div>
          <Brands items={brandsData.featured}></Brands>
          <Latest items={latestData.latest}></Latest>
          <Collections items={collectionsData.featured}></Collections>
          <Trend items={trendingNowData.results}></Trend>
          <Discover items={homeContentData.featuredArticles}></Discover>
          <HowItWorks></HowItWorks>
          <Gift></Gift>
        </div>
      </div>
    </div>
  )
}

Home.getLayout = IndexLayout
